.container {
  width: 100%;
  height: 100%;
  min-height: 0;
}

.scroller {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;

  &:not(.fullscreen) {
    cursor: pointer;
  }
}

.scroller--bordered {
  border-radius: 2px;
}

.thumbs {
  justify-content: center;
  display: flex;
}

.thumbs-wrapper {
  width: 100%;
  margin-top: var(--unit_2);
}

.thumbs-wrapper--bounded {
  margin: var(--unit_2) auto;
  max-width: 718px;
}

.image {
  cursor: pointer;
}

@media (min-width: 1440px) {
  .thumbs-wrapper--bounded {
    max-width: 1036px;
  }
}
