.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lock-icon-wrapper {
  display: inline-flex;
  margin-bottom: 12px;
}

.heading-wrapper {
  padding-bottom: 16px;
  margin: 0;
}

.auth-advantages-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px 20px;
}

.enter-button {
  margin-bottom: 40px;
}
