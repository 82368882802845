.fullscreen-button {
  align-items: center;
  background-color: var(--overlay-default);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0;
  width: 40px;
}
