.container {
  display: flex;
  flex-direction: column;
  width: 528px;
}

.header-wrapper {
  margin: 38px 0 24px;
}

.title {
  margin-bottom: 16px;
}

.form {
  margin-top: 24px;
}

.total-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.info-list {
  margin-bottom: 24px;
}

.info-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.info-item:last-child {
  margin-bottom: 0;
}

.info-list-header {
  margin-bottom: 16px;
}

.icon-wrapper {
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
}

.price-container {
  margin-right: auto;
}

.before-price {
  text-decoration: line-through;
  color: var(--gray60_100);
}

.price {
  display: flex;
  align-items: center;
}

.discount {
  width: 45px;
  height: 20px;
  padding: 0 8px;
  background-color: var(--accent-negative-primary);
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.legal-docs-wrapper {
  margin-top: 24px;
}

.link {
  cursor: pointer;
  text-decoration: none;

  &,
  &:visited {
    color: var(--accent-main-primary);
  }

  &:hover,
  &:focus {
    color: var(--control-main-primary-hovered);
  }

  &:active {
    color: var(--control-main-primary-pressed);
  }
}
