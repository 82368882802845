.row {
  --border-color: #e8e9ec;

  display: flex;
  padding: 16px 0;
  box-shadow: 0 -1px 0 var(--border-color);

  &.expandable:last-of-type {
    box-shadow:
      0 -1px 0 var(--border-color),
      0 1px 0 var(--border-color);
  }
}

.photo,
.area,
.price {
  flex-grow: 1;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
}

.photo {
  width: var(--photo_col_width);
  text-align: left;
}

.area {
  width: var(--area_col_width);
  text-align: left;
}

.area-data-wrapper,
.price-data-wrapper {
  margin-bottom: 4px;
}

.price {
  width: var(--price_col_width);
}
