.container {
  display: grid;
  grid-template-columns: 16px 1fr;
  grid-column-gap: 8px;
  align-items: flex-start;
}

.content,
.icon {
  display: flex;
}

.icon {
  margin-top: 4px;
}
