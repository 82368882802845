.container {
  padding: 0 24px;
  margin: 20px 0;
}

.inner {
  padding: 20px 0 40px;
  border-top: 1px solid var(--gray10_100);
  border-bottom: 1px solid var(--gray10_100);
}

.header {
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
}

.title {
  margin-left: 16px;
}

.top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
}

.top span + span {
  margin-left: 8px;
}

.properties {
  margin: 20px 0;
  padding: 0;
}

.properties li {
  width: 50%;
  display: inline-block;
  list-style: none;
}

.properties li:nth-child(2n) {
  width: calc(50% - 20px);
  margin-left: 20px;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
}

.bullet {
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
