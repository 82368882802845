.container {
  padding: 24px;
  border-top: 1px solid #e4e4e4;

  &:only-child {
    border-top: 0;
  }

  & > div {
    position: relative;
    margin-bottom: 16px;
    padding-left: 26px;
    font-size: 16px;
    line-height: 1.38;
    color: #121212;
    background-repeat: no-repeat;
    background-position: 0 50%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ventilation {
  background-image: url('./icons/ventilation.svg');
  background-size: 14px 14px;
}
.condition {
  background-image: url('./icons/condition.svg');
  background-size: 14px 12px;
}
.heating {
  background-image: url('./icons/heating.svg');
  background-size: 14px 12px;
}
.extinguishing {
  background-image: url('./icons/extinguishing.svg');
  background-size: 10px 14px;
}
