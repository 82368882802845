.container {
  display: flex;
  flex-direction: column;
  width: 528px;
}

.header-wrapper {
  margin-bottom: 12px;
}

.controls-wrapper {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.email-wrapper {
  margin-top: 16px;
}
