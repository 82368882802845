.container {
  padding: var(--unit_4, 16px);
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_3, 12px);
  background-color: var(--gray6_100);
  border-radius: var(--unit_1, 4px);
}

.warning-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  background-image: url('./internal/icons/warning-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.content-container {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_2, 8px);
}

.content-text {
  display: flex;
  flex-direction: column;
  row-gap: var(--unit_3, 12px);
}
