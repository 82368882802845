.container {
  background-color: white;
}

.with-delimiter {
  border-bottom: 1px solid #e4e4e4;
}

.title {
  margin: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}

.list {
  margin: 24px 24px 0;
  padding: 0 0 8px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

.item {
  width: 50%;
  font-size: 14px;
  line-height: 20px;

  &:last-child {
    margin-bottom: 16px;
  }

  span:first-child {
    max-width: 188px;
    color: #7a7a7a;
    flex: 1 1 188px;
  }

  span:last-child {
    flex: 1 1 40%;
  }
}

@media (max-width: 992px) {
  .item {
    width: 100%;
  }
}
