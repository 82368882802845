.container {
  &.open {
    .offers-list-container {
      max-height: 550px;
    }

    .arrow-down-wrapper {
      transform: rotate(180deg);
    }

    .divider {
      opacity: 0;
      margin-left: 16px;
    }
  }
}

.arrow-down-wrapper {
  display: flex;
  transition: transform 400ms linear;
}

.offers-list-container {
  overflow-y: hidden;
  transition: all 400ms linear;
  max-height: 0;
}

.offers-list-wrapper {
  margin-top: 8px;
  margin-bottom: 16px;
  margin-left: 16px;
}

.group-container {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr 100px 180px 160px;
  place-items: center flex-end;
  padding: 16px 0;
}

.divider {
  height: 1px;
  background-color: var(--gray10_100);
  transition: all 400ms linear;
}

.title-col {
  justify-self: flex-start;
}

.title-link {
  text-decoration: none;
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--unit_2, 8px);
  color: var(--decorative-theme-dark);
  line-height: 24px;
  font-size: 16px;

  &:hover {
    color: var(--accent-main-primary);
  }
}

@media (min-width: 1025px) {
  .title-link {
    &::before {
      content: attr(data-label) '';
    }

    &:hover {
      .arrow-out-wrapper {
        visibility: visible;
      }
    }
  }

  .arrow-out-wrapper {
    margin-top: var(--unit_0, 2px);
    visibility: hidden;
  }
}

@media (max-width: 1279px) {
  .title-link {
    &::before {
      content: attr(data-short-label) '';
    }
  }

  .arrow-out-wrapper {
    display: none;
  }
}

.toggle-offers-visibility-button {
  border: none;
  background-color: transparent;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 16px;
  grid-column-gap: 8px;
  align-items: center;
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }
}
