.container {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr 100px 160px 100px;
  align-items: center;
  border-bottom: 1px solid var(--gray10_100);
  padding: 6px 0;
}

.image-col {
  display: flex;
  justify-content: flex-end;
}

.title-link {
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;

  &::before {
    content: attr(data-label) '';
  }
}

.photo-link {
  border: 1px solid var(--gray20_100);
  border-radius: var(--unit_1, 4px);
  overflow: hidden;
  display: flex;
}

@media (max-width: 1279px) {
  .title-link {
    &::before {
      content: attr(data-short-label) '';
    }
  }
}
