.success-header {
  margin-bottom: 40px;
  margin-top: 18px;
  max-width: 576px;
}

.header-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
}

.success-header-icon {
  width: 34px;
  height: 34px;
  background: url('../assets/success-header-icon.png') no-repeat center;
  background-size: cover;
  margin-right: 11px;
}

.partner-link {
  color: var(--accent-main-primary);
}

.hr {
  --margin: 40px;

  margin-top: var(--margin);
  margin-left: calc(var(--margin) * -1);
  height: 1px;
  width: calc(100% + var(--margin) * 2);
  background-color: var(--gray10_100);
}
