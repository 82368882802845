.wrapper {
  position: relative;
  user-select: none;
}

.container {
  margin: 0;
  padding: 0;
  display: flex;
  overflow: scroll hidden;
  list-style: none;
  scrollbar-width: none;
  pointer-events: auto;
  -ms-overflow-style: none;
}

.item {
  cursor: pointer;
}

.container::-webkit-scrollbar {
  display: none;
}

.container li {
  flex: 0 0 auto;
  max-width: 100%;
}

.container li:nth-child(n + 2) {
  margin-left: 8px;
}

.container--fullscreen li:nth-child(n + 2) {
  margin-left: 16px;
}
