.form-success-header {
  margin-top: 18px;
  width: 576px;
}

.form-header-title-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
