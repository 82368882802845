.container {
  display: grid;
  grid-template-columns: 1fr 248px;
  grid-column-gap: 4px;
  overflow: hidden;
  border: 1px solid #c9d1e5;
  border-radius: 8px;
}

.header {
  padding-bottom: 4px;
}

.content {
  margin: 24px;
}

.description {
  margin-bottom: 12px;
}

.button {
  background-color: var(--accent-main-primary);
  padding: 4px 12px;
  border-radius: 4px;
  border: none;
  transition:
    color 0.3s ease-out,
    background-color 0.3s ease-out,
    box-shadow 0.05s;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: var(--control-main-primary-hovered);
  }

  &:focus {
    box-shadow: 0 0 1px 1px var(--stroke-control-focused);
  }

  &:active {
    background-color: var(--control-main-primary-pressed);
  }
}

.phones {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
  color: var(--decorative-theme-dark);
}

.sign {
  margin-top: 4px;

  &:empty {
    display: none;
  }
}

.more-info-image-wrapper {
  display: flex;
  align-self: flex-end;
  width: 248px;
  height: 140px;
}
