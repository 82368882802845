.offer_card_page {
  &-technical {
    margin-top: 8px;
    background-color: white;
    border: solid 1px #e4e4e4;
    border-radius: 3px;

    &:empty {
      display: none;
    }
  }
}

.description-buy-analytics-wrapper {
  border-top: 1px solid #c9d1e5;
  border-bottom: 1px solid #c9d1e5;
  background: var(--decorative-theme-white);
  margin: 0 24px;
}

.buy-analytics-button {
  padding: 0 24px 24px;
}

.only-button {
  padding: 24px;
}

.description-wrapper {
  margin: 48px 0 40px;
}

.area-parts-wrapper {
  padding: 48px 24px 0;
}

@page {
  margin: 15mm 0;
  size: a4;
}

@media print {
  html,
  body {
    display: block;
    overflow: visible;
    width: 210mm;
  }

  /* stylelint-disable-next-line */
  :global(#adaptive-header) {
    display: none;
  }

  /* stylelint-disable-next-line */
  :global(#frontend-offer-card) {
    display: block;
  }
}
