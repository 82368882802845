.item {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  list-style: none;
  font-size: 16px;
  line-height: 22px;

  &:last-child {
    margin-bottom: 0;
  }
}

.name {
  max-width: 220px;
  color: #7a7a7a;
  flex: 1 1 220px;
}

.value {
  padding-left: 24px;
}

@media print {
  .name,
  .value {
    font-size: 12px;
    line-height: 16px;
  }
}
