.container {
  width: 100%;
  margin-bottom: -6px;

  --photo_col_width: 64px;
  --area_col_width: calc(60% - 64px);
  --price_col_width: 40%;
}

.show-all-areas-container {
  margin: 21px 0 2px;
  display: block;
}
