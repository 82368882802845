.wrapper {
  position: relative;
  overflow: hidden;
  height: 328px;
}

@media (min-width: 1024px) {
  .wrapper {
    height: 455px;
  }
}
