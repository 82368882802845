.container {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  background: none;
  border: 0;
  pointer-events: none;
}

.icon {
  margin: 0 5px 0 0;

  > svg {
    width: 14px;
    height: 14px;
  }
}
