.container {
  display: flex;
  flex-direction: column;
}

.heading-wrapper {
  padding-bottom: 4px;
}

.area-part {
  display: grid;
  grid-template-columns: 1fr 200px 188px;
  grid-column-gap: 8px;
  padding: 16px 0;
  place-items: center flex-end;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray20_100);
  }
}

.area-col {
  justify-self: flex-start;
}
