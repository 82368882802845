.container {
  background-color: var(--decorative-theme-white);
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.uplayer {
  z-index: 200;
}

.no-scroll {
  overflow: hidden;
}

.fullscreen {
  position: fixed;
  inset: 0;
  z-index: 100;
}

.hidden {
  display: none;
}

.info {
  background-color: var(--decorative-theme-white);
  flex: 0 0 auto;
  height: 100%;
  padding: 48px 40px;
  position: relative;
  width: 358px;
}

.close-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 6px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.info-section {
  margin-bottom: 34px;
}

.info-block {
  margin-bottom: 4px;
}

.contact-button {
  margin: 16px 0;
}

.view {
  flex: auto;
  height: 100%;
}

.fullscreen-button {
  height: 40px;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
}
