.wrapper {
  padding: 40px 0;
}

.header {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;

  &-description {
    display: block;
    margin-top: 4px;
  }
}

.secondary-header {
  margin-bottom: 24px;
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-buttons {
  display: flex;
  gap: 16px;
}

.content {
  margin: 0 0 32px;
  padding: 0;
  display: flex;
  gap: 16px;

  &-column,
  &-column-single {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }

  &-column {
    width: 50%;
  }

  &-column-single {
    width: 100%;
  }

  &-item {
    list-style: none;
    display: flex;
    gap: 13px;
    align-items: flex-start;
  }

  &-item-icon {
    width: 16px;
    height: 16px;
    margin: 4px 0;
  }
}

.price-wrapper {
  display: flex;
  align-items: center;
}

.price-no-discount {
  margin-left: 6px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    height: 1px;
    width: 100%;
    background: var(--accent-negative-primary);
    transform: rotate(-10deg);
  }
}

@media (max-width: 1200px) {
  .content {
    gap: 12px;
    flex-direction: column;

    &-column {
      width: 100%;
    }
  }

  .footer {
    flex-direction: row;

    > button {
      width: 100%;
    }
  }
}
