.container {
  width: 48px;
  height: 48px;
}

.no-media {
  width: 48px;
  height: 48px;
  background-image: url('./assets/no-media.svg');
  background-repeat: no-repeat;
}
