.button {
  line-height: 22px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  color: var(--accent-main-primary);
  font-size: 16px;
  background: none;
  border: none;
}

.button:hover {
  color: var(--accent-main-primary);
}
