.specialties-title {
  padding-bottom: var(--unit_10, 40px);
}

.specialties {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 272px);
  grid-gap: var(--unit_4, 16px);

  & + .specialties-other {
    margin-top: var(--unit_6, 16px);
  }
}

.specialty {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: var(--unit_2, 8px);

  &::before {
    display: inline-block;
    width: 16px;
    height: 16px;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
  }

  &.manufacture::before {
    background-image: url('internal/assets/ic-manufacture.svg');
  }

  &.office::before {
    background-image: url('internal/assets/ic-office.svg');
  }

  &.trade-area::before {
    background-image: url('internal/assets/ic-tradeArea.svg');
  }

  &.warehouse::before {
    background-image: url('internal/assets/ic-warehouse.svg');
  }
}

.button-collapsed {
  margin-top: var(--unit_3, 12px);
}
