.group {
  width: calc(33% - 20px);
  margin-right: 20px;
  margin-bottom: 32px;
}

.group h4 {
  margin-bottom: 8px;
}

.item {
  position: relative;
  display: inline-block;
  padding: 8px 26px;
  width: 100%;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: var(--decorative-theme-dark);

  &::before {
    position: absolute;
    left: 0;
    width: 16px;
    height: 22px;
    content: '';
    background-repeat: no-repeat;
    background-position: center;
  }

  &.iconrenovation::before {
    background-image: url('./assets/IconRenovation.svg');
  }

  &.iconoffice::before {
    background-image: url('./assets/IconOffice.svg');
  }

  &.iconwifi::before {
    background-image: url('./assets/IconWiFi.svg');
  }

  &.iconinternet::before {
    background-image: url('./assets/IconInternet.svg');
  }

  &.iconpin::before {
    background-image: url('./assets/IconPin.svg');
  }

  &.iconsecurity::before {
    background-image: url('./assets/IconSecurity.svg');
  }

  &.iconprint::before {
    background-image: url('./assets/IconPrint.svg');
  }

  &.icondocument::before {
    background-image: url('./assets/IconDocument.svg');
  }

  &.iconflipchart::before {
    background-image: url('./assets/IconFlipchart.svg');
  }

  &.icontea::before {
    background-image: url('./assets/IconTea.svg');
  }

  &.iconfruit::before {
    background-image: url('./assets/IconFruit.svg');
  }

  &.iconfridge::before {
    background-image: url('./assets/IconFridge.svg');
  }

  &.iconrestaurant::before {
    background-image: url('./assets/IconRestaurant.svg');
  }

  &.iconsupport::before {
    background-image: url('./assets/IconSupport.svg');
  }

  &.iconreception::before {
    background-image: url('./assets/IconReception.svg');
  }

  &.iconcleaning::before {
    background-image: url('./assets/IconCleaning.svg');
  }

  &.iconlocker::before {
    background-image: url('./assets/IconLocker.svg');
  }

  &.iconphone::before {
    background-image: url('./assets/IconPhone.svg');
  }

  &.iconcomment::before {
    background-image: url('./assets/IconComment.svg');
  }

  &.iconarmchair::before {
    background-image: url('./assets/IconArmchair.svg');
  }

  &.iconcalendar::before {
    background-image: url('./assets/IconCalendar.svg');
  }

  &.iconparking::before {
    background-image: url('./assets/IconParking.svg');
  }

  &.iconbike::before {
    background-image: url('./assets/IconBike.svg');
  }

  &.iconfitness::before {
    background-image: url('./assets/IconFitness.svg');
  }
}
