.controls {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 70px;
  color: white;
  background-color: black;
  justify-content: flex-end;
  align-items: center;

  button.favorites {
    display: flex;
    color: white;
    border-radius: 4px;
    padding: 4px 12px 4px 13px;
    background-color: rgba(255, 255, 255, 0.15);
    transition:
      color 0.3s ease,
      background-color 0.3s ease;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.controls--next-compat {
  position: relative;
  flex: 0 0 auto;
  padding-right: 64px;
}

.favorites-wrapper {
  margin-left: 20px;
}

.title {
  position: absolute;
  left: 20px;
  text-align: left;
}

.close {
  margin-right: 20px;
  margin-left: 32px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.phone {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.38;
}

.show-phone {
  color: white;
  background-color: var(--accent-main-primary);
  font-weight: bold;
  white-space: nowrap;
  padding: 4px 12px 4px 13px;
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  border: none;
  transition:
    color 0.3s ease-out,
    background-color 0.3s ease-out,
    box-shadow 0.05s;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #256ba5;
  }

  &:focus {
    box-shadow: 0 0 0 3px #2b87db7a;
  }

  &:active {
    transform: scale(0.98);
  }
}

.show-phone-icon-wrapper {
  vertical-align: middle;
  padding-right: 7px;

  & > svg {
    width: 14px;
    height: 14px;
  }
}
